import dataGameOne from '@/data/gameOneData.json';
import { useGame1Store } from '@/store/games/game1Store';
import { useOverlayStore } from '@/store/overlayStore';
import { getNextJobGame1 } from '@/services/games/game1/gameOneUtils';
import { type JobGame1, type Lever, type Risk } from '@/types/games/game1/types';
import {
    handleGoToGameStatus,
    handleTabletStatus,
    handleUpdateOverlayDescriptions,
    handleUpdateOverlayIndex,
    handleUpdateOverlayType,
} from '@/services/global/globalHandleFunctions';
import {
    emitSocketEvent,
    getJobsData,
    tabletStatusEventConfig,
} from '@/services/global/globalUtils';
import {
    GAMESTATUS,
    OverlayType,
    type OverlayDescription,
} from '@/types/global/types';

export const handleUpdateRisk = (risk: Risk): void => {
    emitSocketEvent('send_update_risk', {
        risk,
    });
};

export const handleUpdateLevers = (levers: Lever[]): void => {
    emitSocketEvent('send_update_levers', {
        levers,
    });
};

export const handleUpdateGameOneAudioJobStatus = (
    audioShouldPlay: boolean
): void => {
    emitSocketEvent('send_update_game_one_audio_job_status', {
        audioShouldPlay,
    });
};

export const handleUpdateRiskOrLeverStatus = (
    id: number,
    type: 'risks' | 'levers',
    isSelected: boolean
): void => {
    emitSocketEvent('send_update_risk_or_lever_selected_status', {
        id,
        type,
        isSelected,
    });
};

export const handleGoToNextOrPreviousJobOverlay = (
    action: 'next' | 'previous'
): void => {
    const { overlayDescriptions, currentOverlayIndex } = useOverlayStore.getState();
    const currentJob = useGame1Store.getState().job;
    const leversFound = useGame1Store.getState().leversFound;

    const nextIndex =
        action === 'next' ? currentOverlayIndex + 1 : currentOverlayIndex - 1;

    const isGoodAnswer = overlayDescriptions[nextIndex]?.title === 'Bonne réponse';

    const isLastJob = dataGameOne.jobs.at(-1)?.jobId === currentJob.id;

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescriptions &&
            currentOverlayIndex === overlayDescriptions.length - 1;

        // If it's not the last message, we simply go to the next message
        if (!isLastOverlayMessage) {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
            handleUpdateOverlayType(
                isGoodAnswer ? OverlayType.SUCCESS : OverlayType.ERROR
            );
            return;
        }

        const tabletsStatus = () => {
            if (currentJob.risksValidated && leversFound)
                return tabletStatusEventConfig.allRisksOrLeversUnlocked;
            if (currentJob.risksValidated)
                return tabletStatusEventConfig.risksFound;
            return tabletStatusEventConfig.allRisksOrLeversUnlocked;
        };
        handleUpdateOverlayDescriptions([], OverlayType.NEUTRAL);
        handleTabletStatus(tabletsStatus());

        // If it is not the last job
        if (currentJob.risksValidated && currentJob.leversValidated && !isLastJob) {
            const nextJob = getNextJobGame1(
                getJobsData(dataGameOne.jobs) as JobGame1[]
            );

            handleRisksAndLeversReset();
            handleCurrentJobGame1(nextJob);
        }

        // If it is the last job
        if (isLastJob && currentJob.risksValidated && currentJob.leversValidated) {
            handleGoToGameStatus(GAMESTATUS.GAME1PREVENTION);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescriptions && currentOverlayIndex === 0;

        if (!isFirstMessage) {
            handleUpdateOverlayIndex(currentOverlayIndex - 1);
            handleUpdateOverlayType(
                isGoodAnswer ? OverlayType.SUCCESS : OverlayType.ERROR
            );
        }
    }
};

export const handleValidateGame1RisksAndLevers = (
    type: 'levers' | 'risks'
): void => {
    const currentJob = useGame1Store.getState().job;
    const items = useGame1Store.getState()[type];
    const selectedItems = items.filter((item) => item.isSelected);

    // List of good leversId or riskId for the current job
    const correctItemsId = dataGameOne.jobs.find(
        (job) => job.jobId === currentJob.id
    )![`${type}Id`];

    // List of the selected leversId or riskId
    const selectedItemsId = selectedItems.map((item) => item.id);

    const responseText = currentJob[`${type}ResponseText`].filter((response) =>
        selectedItemsId.includes(response.id)
    );

    // Transform the responseText to the overlay format
    const formattedResponseText: OverlayDescription[] = responseText.map(
        (response, index) => {
            const isCorrect = correctItemsId.includes(selectedItemsId[index]!);
            return {
                id: response.id,
                audio: response.audioPath,
                character: currentJob.firstName,
                text: response.responseText,
                title: isCorrect ? 'Bonne réponse' : 'Mauvaise réponse',
            };
        }
    );

    const isAllCorrect = selectedItemsId.every((id: number) =>
        correctItemsId.includes(id)
    );

    handleTabletStatus({
        blue: true,
        red: false,
        green: false,
        orange: false,
    });

    // Update the overlay descriptions
    handleUpdateOverlayDescriptions(
        formattedResponseText,
        formattedResponseText[0].title === 'Bonne réponse'
            ? OverlayType.SUCCESS
            : OverlayType.ERROR
    );

    // If the selected items are correct, update the job status
    if (isAllCorrect) {
        handleCurrentJobGame1({ ...currentJob, [`${type}Validated`]: true });
    }
};

export const handleRisksOrLeversFoundStatus = (
    type: 'risks' | 'levers',
    value: boolean
): void => {
    emitSocketEvent('send_update_risks_or_levers_found_status', {
        type,
        value,
    });
};

export const handleCurrentJobGame1 = (
    currentJob: Omit<JobGame1, 'jobId'>
): void => {
    emitSocketEvent('send_update_job_1', {
        currentJob,
    });
};

export const handleRisksAndLeversReset = (): void => {
    emitSocketEvent('send_reset_risks_and_levers_selected_status', {});
};

export const handleAllRisksFound = (): void => {
    handleTabletStatus(tabletStatusEventConfig.allRisksOrLeversUnlocked);
    handleRisksOrLeversFoundStatus('risks', true);
};

export const handleAllLeversFound = (): void => {
    handleUpdateLevers(
        dataGameOne.levers.map((lever) => ({ ...lever, isSelected: false }))
    );
    handleTabletStatus(tabletStatusEventConfig.allRisksOrLeversUnlocked);
    handleRisksOrLeversFoundStatus('levers', true);
};
