import { Trickyberpunk } from '@/assets/images/global';
import { Sound1 } from '@/assets/sounds/soundsEffects';
import globalData from '@/data/global.json';
import Footer from '@/features/devices/tablets/components/HUD/Footer';
import Header from '@/features/devices/tablets/components/HUD/Header';
import ActionButton from '@/features/devices/tablets/components/buttons/ActionButton';
import FullScreenButton from '@/features/devices/tablets/components/fullScreenButton/FullScreenButton';
import tabletStyle from '@/features/devices/tablets/components/screens/tabletScreen.module.scss';
import useCurrentOverlay from '@/hooks/useCurrentOverlay';
import useReSynchronizeDevice from '@/hooks/useReSynchronizeDevice';
import { handleUpdateJobGame2 } from '@/services/games/game2/gameTwoHandleFunctions';
import { playSound } from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useGame2Store } from '@/store/games/game2Store';
import { useEffect } from 'react';
import { GAMESTATUS } from '@/types/global/types';
import UnlockGameTwo from '@/features/components/gameOne/UnlockGameTwo';


const BlueActionSwitch: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const { blue } = useDeviceStore.use.deviceStatus();
    const selectedJob = useGame2Store.use.selectedJob();
    const { currentText } = useCurrentOverlay();
    const isTabletInactive = !blue.isActivated;

    useReSynchronizeDevice('blue');

    useEffect(() => {
        document.title = `🔵 ${blue.label}`;
        emitSocketEvent('send_synchronize_device', {
            deviceColor: 'blue',
        });
    }, [blue.label]);

    const handleRender = () => {
        switch (gameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <>
                        <Header />
                        <ActionButton
                            title={globalData.global.start}
                            action='startGame'
                            color='orange'
                        />
                        <Footer />
                    </>
                );
            case GAMESTATUS.GAME1UNLOCK:
                return <UnlockGameTwo />;
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1INTRODUCTION:
            case GAMESTATUS.GAME3INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
            case GAMESTATUS.GAME4ACTION:
            case GAMESTATUS.GAME1PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME2INTRODUCTION:
            case GAMESTATUS.GAME4UNLOCK:
                return (
                    <ActionButton
                        action='goToNextOverlay'
                        title={globalData.global.next}
                        color='orange'
                    />
                );
            case GAMESTATUS.GAME1ACTION:
                return (
                    <ActionButton
                        action='goToNextJobOverlay'
                        title={globalData.global.next}
                        color='orange'
                    />
                );
            case GAMESTATUS.GAME2ACTION: {
                return currentText ? (
                    <ActionButton
                        action='goToNextOverlay'
                        title={globalData.global.next}
                        color='orange'
                    />
                ) : (
                    <FullScreenButton
                        onClick={() => {
                            playSound(Sound1);
                            handleUpdateJobGame2(selectedJob!, 'NOT_EXPOSED');
                        }}
                        gradientColor='blue'
                        subtext={globalData.tablet.jobRepartitionClick}
                        colorOfSubtext='blue'
                        text={globalData.tablet.underexposed}
                    />
                );
            }
            case GAMESTATUS.GAME3ACTION:
                return (
                    <ActionButton
                        action='validateGame3'
                        title={globalData.global.next}
                        color='orange'
                    />
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={tabletStyle.blueTabletContainer}>
            {isTabletInactive ? (
                <>
                    <Header />
                    <img
                        src={Trickyberpunk}
                        alt='Logo tricky'
                        className={tabletStyle.trickyberpunk}
                    />
                    <Footer />
                </>
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default BlueActionSwitch;
